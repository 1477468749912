import React from "react"

import Layout from "@src/components/Layout"
import OG from "@src/icons/og/OG_Landing.jpeg"
import retentionIcon from "@src/icons/v2/retention-policy.svg"
import headerIcon from "@src/icons/v2/header-security.svg"
import cdnIcon from "@src/icons/v2/cdn.svg"
import accessIcon from "@src/icons/v2/access-to-data.svg"
import sdlcIcon from "@src/icons/v2/sdlc.svg"
import permissionedIcon from "@src/icons/v2/permissioned-keys.svg"

import Splash from "../Features/components/Splash"
import ImageColumn from "../Features/components/ImageColumn"
import Horizontal from "../Features/components/Horizontal"
import Features from "../Features/components/Features"
import Row from "../components/Row"

import * as styles from "./Security.module.scss"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Canonic | Security"
      description="The simplest way for agile teams to build internal tools. Focus on critical tasks and automate the rest."
      url="https://canonic.dev/security"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Built with security and authentication in mind."
        subtitle="We use industry standard security practices to ensure that your data is locked and secure."
      />
      <Row>
        <h2 className={styles.title}>Secure by Design</h2>
        <p className={styles.subtitle}>
          We frequently evaluate risks, threats & vulnerabilities to ensure the
          security of our systems & your data.
        </p>

        <Horizontal>
          <ImageColumn
            title="Deployed worldwide on a scalable & robust infrastructure."
            subtitle="Our infrasturcture is deployed on ISO 27001 certified AWS Data Centers around the world."
            noImage
          />
          <ImageColumn
            title="Role based authentication and governance."
            subtitle="Designate roles and permissions for your team members to ensure secure & compliant collaboration."
            reverse
            noImage
          />
          <ImageColumn
            title="Encryption at rest combined with frequent backups"
            subtitle="Data is encrypted in transit with industry standard SSL. Sensitive data is encrypted at rest along with frequent backups."
            noImage
          />
        </Horizontal>
      </Row>
      <Row>
        <h2 className={styles.title}>Reliable features to secure your data.</h2>
        <br />
        <br />
        <Features
          numPerRow={3}
          features={[
            {
              title: "Permissioned API Keys",
              icon: permissionedIcon,
              subtitle:
                "Generate permissioned API keys for different roles on the basis of least priveleged access.",
            },
            {
              title: "Secure Header Policies",
              icon: headerIcon,
              subtitle:
                "Everything is transmitted over SSL, and we ensure our SSL configuration is always an A+.",
            },
            {
              title: "CDN",
              icon: cdnIcon,
              subtitle:
                "We leverage AWS Cloudfront for serving all data through a global content delivery network.",
            },
            {
              title: "Access to data",
              icon: accessIcon,
              subtitle:
                "Access to data is tighly controlled, and we log all access and audit vulnerabilities regularly.",
            },
            {
              title: "Data retention policy",
              icon: retentionIcon,
              subtitle:
                "Engineered in a way that your data is stored only as long as you need it. Can be deleted upon your request.",
            },
            {
              title: "Secure SDLC",
              icon: sdlcIcon,
              subtitle:
                "Security is a major focus in our dev process. We hold a detailed security evaluation before every release.",
            },
          ]}
        />
      </Row>
    </Layout>
  )
}

export default IndexPage
