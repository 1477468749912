import React from "react"
import * as styles from "./ImageColumn.module.scss"

export default function ImageColumn({
  title,
  subtitle,
  noImage,
  screenshot,
  icon: Icon,
}) {
  return (
    <div className={styles.automatedWorkflowsText}>
      {!noImage && (
        <figure className={styles.automatedWorkflowsIcon}>
          {screenshot ? (
            <img className={styles.screenshot} src={screenshot} />
          ) : Icon ? (
            typeof Icon === "string" ? (
              <img className={styles.icon} src={Icon} />
            ) : (
              <Icon className={styles.icon} />
            )
          ) : null}
        </figure>
      )}
      <h3 className={styles.automatedWorkflowsSmallTitle}>{title}</h3>
      <p className={styles.automatedWorkflowsSubtitle}>{subtitle}</p>
    </div>
  )
}
